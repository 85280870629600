import {
  ANODE,
  CATHODE,
  COBALT,
  LITHIUM,
  RARE_EARTHS,
  LITHIUM_ION_BATTERIES,
  NATURAL_GRAPHITE,
  NICKEL,
  PRICE_DASHBOARD,
  SYNTHETIC_GRAPHITE,
  MANGANESE,
  PHOSPHATE,
  FLUORSPAR,
  SILICON_ANODE,
  SODIUM_ION_BATTERIES,
  SOLID_STATE,
  RECYCLING,
  SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES,
  SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM,
  SUBSCRIPTION_PRICE_ASSESSMENTS_COBALT,
  SUBSCRIPTION_PRICE_ASSESSMENTS_NICKEL,
  SUBSCRIPTION_PRICE_ASSESSMENTS_NATURAL_GRAPHITE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES,
  SUBSCRIPTION_PRICE_ASSESSMENTS_ANODE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_CATHODE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS,
  SUBSCRIPTION_PRICE_ASSESSMENTS_RARE_EARTHS,
  COPPER,
} from '~/constants/services/constants'
import { getMenu, getRoutes, getSidebar } from '~/constants/services/methods'

const getMenuByProductName = () => {
  return [
    { name: 'portal', path: '' },
    { name: 'market insights', path: 'market-insights' },
    { name: 'data & reports', path: 'data-reports' },
  ]
}

export const MARKET_PRICE_DASHBOARD = {
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES,
  slug: PRICE_DASHBOARD.slug,
  menu: getMenu(
    PRICE_DASHBOARD.menu,
    PRICE_DASHBOARD.slug,
    '',
    true,
    'chart-builder',
  ),
  routes: getRoutes(PRICE_DASHBOARD.slug, 'chart-builder', false),
  sidebar: getSidebar(
    PRICE_DASHBOARD.slug,
    PRICE_DASHBOARD.sidebar,
    PRICE_DASHBOARD.iconLine,
    [
      { name: 'chart builder', path: 'chart-builder' },
      { name: 'watchlists', path: 'watchlist' },
    ],
    '',
  ),
}

export const MARKET_LITHIUM = {
  slug: LITHIUM.slug,
  sidebar: getSidebar(LITHIUM.slug, LITHIUM.sidebar, LITHIUM.iconLine, [
    { name: 'portal', path: '' },
    { name: 'market insights', path: 'market-insights' },
    { name: `Lithium Prices`, path: 'lithium-prices' },
    { name: 'data & reports', path: 'data-reports' },
    { name: 'sustainability', path: 'sustainability' },
  ]),
}

export const MARKET_COPPER = {
  slug: COPPER.slug,
  sidebar: getSidebar(
    COPPER.slug,
    COPPER.sidebar,
    COPPER.iconLine,
    [
      { name: 'portal', path: 'portal' },
      { name: 'market insights', path: 'market-insights' },
      { name: 'data & reports', path: 'data-reports' },
    ],
    '/copper',
  ),
}

export const MARKET_COBALT = {
  slug: COBALT.slug,
  sidebar: getSidebar(COBALT.slug, COBALT.sidebar, COBALT.iconLine, [
    { name: 'portal', path: '' },
    { name: 'market insights', path: 'market-insights' },
    { name: 'Cobalt Prices', path: 'cobalt-prices' },
    { name: 'data & reports', path: 'data-reports' },
    { name: 'Sustainability', path: 'sustainability' },
  ]),
}

export const MARKET_NICKEL = {
  slug: NICKEL.slug,
  sidebar: getSidebar(NICKEL.slug, NICKEL.sidebar, NICKEL.iconLine, [
    { name: 'portal', path: '' },
    { name: 'market insights', path: 'market-insights' },
    { name: 'Nickel Prices', path: 'nickel-prices' },
    { name: 'data & reports', path: 'data-reports' },
    { name: 'sustainability', path: 'sustainability' },
  ]),
}

export const MARKET_NATURAL_GRAPHITE = {
  slug: NATURAL_GRAPHITE.slug,
  sidebar: getSidebar(
    NATURAL_GRAPHITE.slug,
    NATURAL_GRAPHITE.sidebar,
    NATURAL_GRAPHITE.iconLine,
    [
      { name: 'portal', path: '' },
      { name: 'market insights', path: 'market-insights' },
      { name: 'Natural Graphite Prices', path: 'natural-graphite-prices' },
      { name: 'data & reports', path: 'data-reports' },
      { name: 'Sustainability', path: 'sustainability' },
    ],
  ),
}

export const MARKET_SYNTHETIC_GRAPHITE = {
  slug: SYNTHETIC_GRAPHITE.slug,
  sidebar: getSidebar(
    SYNTHETIC_GRAPHITE.slug,
    SYNTHETIC_GRAPHITE.sidebar,
    SYNTHETIC_GRAPHITE.iconLine,
    [
      { name: 'portal', path: '' },
      { name: 'market insights', path: 'market-insights' },
      { name: 'Synthetic Graphite Prices', path: 'synthetic-graphite-prices' },
      { name: 'data & reports', path: 'data-reports' },
      { name: 'Sustainability', path: 'sustainability' },
    ],
  ),
}

export const MARKET_LITHIUM_ION_BATTERIES = {
  slug: LITHIUM_ION_BATTERIES.slug,
  sidebar: getSidebar(
    LITHIUM_ION_BATTERIES.slug,
    LITHIUM_ION_BATTERIES.sidebar,
    LITHIUM_ION_BATTERIES.iconLine,
    [
      { name: 'portal', path: '' },
      { name: 'market insights', path: 'market-insights' },
      {
        name: `Lithium ion Batteries Prices`,
        path: 'lithium-ion-batteries-prices',
      },
      { name: 'data & reports', path: 'data-reports' },
      { name: 'capacity database', path: 'capacity-database' },
    ],
  ),
}

export const MARKET_ANODE = {
  slug: ANODE.slug,
  sidebar: getSidebar(ANODE.slug, ANODE.sidebar, ANODE.iconLine, [
    { name: 'portal', path: '' },
    { name: 'market insights', path: 'market-insights' },
    { name: `Anode Prices`, path: 'anode-prices' },
    { name: 'data & reports', path: 'data-reports' },
    { name: 'capacity database', path: 'capacity-database' },
  ]),
}

export const MARKET_CATHODE = {
  slug: CATHODE.slug,
  sidebar: getSidebar(CATHODE.slug, CATHODE.sidebar, CATHODE.iconLine, [
    { name: 'portal', path: '' },
    { name: 'market insights', path: 'market-insights' },
    { name: `Cathode Prices`, path: 'cathode-prices' },
    { name: 'data & reports', path: 'data-reports' },
    { name: 'capacity database', path: 'capacity-database' },
  ]),
}

export const MARKET_RARE_EARTHS = {
  slug: RARE_EARTHS.slug,
  sidebar: getSidebar(
    RARE_EARTHS.slug,
    RARE_EARTHS.sidebar,
    RARE_EARTHS.iconLine,
    [
      { name: 'portal', path: '' },
      { name: 'market insights', path: 'market-insights' },
      { name: `Rare Earths Prices`, path: 'rare-earths-prices' },
      { name: 'data & reports', path: 'data-reports' },
      { name: 'sustainability', path: 'sustainability' },
    ],
  ),
}

export const MARKET_MANGANESE = {
  slug: MANGANESE.slug,
  sidebar: getSidebar(
    MANGANESE.slug,
    MANGANESE.sidebar,
    MANGANESE.iconLine,
    getMenuByProductName(),
  ),
}

export const MARKET_PHOSPHATE = {
  slug: PHOSPHATE.slug,
  sidebar: getSidebar(
    PHOSPHATE.slug,
    PHOSPHATE.sidebar,
    PHOSPHATE.iconLine,
    getMenuByProductName(),
  ),
}

export const MARKET_FLUORSPAR = {
  slug: FLUORSPAR.slug,
  sidebar: getSidebar(
    FLUORSPAR.slug,
    FLUORSPAR.sidebar,
    FLUORSPAR.iconLine,
    getMenuByProductName(),
  ),
}

export const MARKET_SILICON_ANODE = {
  slug: SILICON_ANODE.slug,
  sidebar: getSidebar(
    SILICON_ANODE.slug,
    SILICON_ANODE.sidebar,
    SILICON_ANODE.iconLine,
    getMenuByProductName(),
  ),
}

export const MARKET_SODIUM_ION_BATTERIES = {
  slug: SODIUM_ION_BATTERIES.slug,
  sidebar: getSidebar(
    SODIUM_ION_BATTERIES.slug,
    SODIUM_ION_BATTERIES.sidebar,
    SODIUM_ION_BATTERIES.iconLine,
    [
      ...getMenuByProductName(),
      { name: 'capacity database', path: 'capacity-database' },
    ],
  ),
}

export const MARKET_SOLID_STATE = {
  slug: SOLID_STATE.slug,
  sidebar: getSidebar(
    SOLID_STATE.slug,
    SOLID_STATE.sidebar,
    SOLID_STATE.iconLine,
    getMenuByProductName(),
  ),
}

export const MARKET_RECYCLING = {
  slug: RECYCLING.slug,
  sidebar: getSidebar(RECYCLING.slug, RECYCLING.sidebar, RECYCLING.iconLine, [
    { name: 'portal', path: '' },
    { name: 'market insights', path: 'market-insights' },
    { name: `Black Mass Prices`, path: 'black-mass-prices' },
    { name: 'data & reports', path: 'data-reports' },
  ]),
}

export const MARKETS_AVAILABLE_FOR_TICKER = {
  [SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES]: `/${PRICE_DASHBOARD.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM]: `/${LITHIUM.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_COBALT]: `/${COBALT.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_NICKEL]: `/${NICKEL.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_NATURAL_GRAPHITE]: `/${NATURAL_GRAPHITE.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE]: `/${SYNTHETIC_GRAPHITE.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES]: `/${LITHIUM_ION_BATTERIES.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_ANODE]: `/${ANODE.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_CATHODE]: `/${CATHODE.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS]: `/${RECYCLING.slug}/`,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_RARE_EARTHS]: `/${RARE_EARTHS.slug}/`,
}

export const getMarketBySubscription = (subscriptionName) => {
  return MARKETS_AVAILABLE_FOR_TICKER[subscriptionName]
}
